
import Swal from 'sweetalert2'
import { mapMutations, mapActions } from 'vuex'

export default {
  layout: 'landing',
  components: {
  },
  data () {
    return {
      error_message: '',
      otp: '',
      phone: '',
      otpOption: 'phone',
      verify: 1,
      email: '',
      code: '',
      isNotLineApp: false,
      lineProfile: null,
      lineToken: null,
      userProfile: null,
      liffID: this.$line().orders.liffId,
      isSendOTP: false,
      error: {
        phone: false,
        otp: false
      }
    }
  },
  watch: {
    lineProfile: {
      handler (lineProfile) {
        console.log('lineProfile changed in line.vue:', lineProfile)
      },
      immediate: true
    }
  },
  created () {
    // console.log('>>>> created:1 <<<<', this.$lineCRM(), ' ENV >> ', process.env.NODE_ENV)
    if (process.client) {
      const isStaging = process.env.NODE_ENV !== 'production'
      if (isStaging) {
        // Import VConsole only in the staging environment
        import('vconsole').then(({ default: VConsole }) => {
          const vConsole = new VConsole()
          console.log('vConsole', vConsole)
        })
      }
      // console.log('>>>> created:initLiff <<<<')
      this.initLiff()
    }
  },
  mounted () {
  },
  methods: {
    ...mapMutations([
      'showLoading',
      'hideLoading'
    ]),
    ...mapActions([
      'initializeApp',
      'isTokenExpired'
    ]),
    async initLiff () {
      const liffID = this.$lineCRM().liffId
      // console.log('>>> initLiff2 <<<')
      // console.log('liffID', liffID, process.env.NODE_ENV)
      await window.liff.init({ liffId: liffID })
      if (window.liff.isInClient()) {
        if (window.liff.isLoggedIn()) {
          try {
            const decodedIDToken = await window.liff.getDecodedIDToken()
            const lineProfile = await window.liff.getProfile()
            this.lineToken = await window.liff.getIDToken()
            this.lineProfile = decodedIDToken
            // ผู้ใช้ได้เข้าสู่ระบบแล้ว
            // console.log('User is logged in', decodedIDToken)
            // console.log('Line profile', lineProfile)
            this.email = decodedIDToken.email ? decodedIDToken.email : null
          } catch (error) {
            console.log('>>ERRRRR<<', error.message)
            window.liff.logout()
            window.liff.login()
            // window.liff.login({
            //   scope: 'profile openid email', // Basic scopes (add 'email' if you want to make it mandatory)
            //   prompt: 'consent'
            // })
          }
        } else {
          // ผู้ใช้ยังไม่ได้เข้าสู่ระบบ
          console.log('User is not logged in')
          // window.liff.logout()
          window.liff.login()
        }
      } else {
        this.$router.push('/')
      }
      // console.log('getLOCALTEST', localStorage.getItem('test'))
      // console.log('getLOCALLINE', localStorage.getItem('line'))
      // console.log('getLOCALDECODED', localStorage.getItem('decodedIDToken'))

      // const liffID = this.$lineCRM().liffId
      // window.liff.init({ liffId: liffID }).then(async () => {
      //   let decodedIDToken = JSON.parse(localStorage.getItem('decodedIDToken'))
      //   console.log('decode ::', decodedIDToken)
      //   console.log('login ::', window.liff.isLoggedIn())

      //   if (!decodedIDToken || !window.liff.isLoggedIn()) {
      //     console.log('goto loginnnn')
      //     window.liff.login()
      //   } else {
      //     decodedIDToken = window.liff.getDecodedIDToken()

      //     // บันทึก decodedIDToken ลงใน Local Storage
      //     localStorage.setItem('decodedIDToken', JSON.stringify(decodedIDToken))
      //     localStorage.setItem('line', true)
      //     console.log('>>>>>> GET LOCAL DECODE localStorage.getItem("decodedIDToken") <<<<<<', localStorage.getItem('decodedIDToken'))
      //     await this.checkSessionValidity()
      //   }
      // }).catch((err) => {
      //   console.error('LIFF Initialization failed:', err)
      // })
    },
    async sendOTP () {
      if (this.phone.length !== 10) {
        this.error.phone = true
        return
      }
      this.error.phone = false
      this.isSendOTP = true

      this.showLoading()
      const url = 'otp-login'

      try {
        const result = await this.$axios.$post(url, { otpOption: this.otpOption, phone: this.phone })
        this.userId = result.data.id
        if (result.status === false) {
          this.setAlert({
            show: true,
            message: result.message,
            header: 'แจ้งเตือน'
          })
        } else {
          this.setAlert({
            show: true,
            header: 'ไม่สามารถทำรายการได้'
          })
        }
      } catch (e) {
        console.log('errror', e.message)
      }

      this.hideLoading()
      return false
    },
    async verifyOTP () {
      console.log('>>>> VERIFY OTP <<<<', this.otp.length, this.error.otp)
      if (this.otp.length !== 6) {
        this.error.otp = true
        return
      }
      this.error.otp = false
      this.showLoading()
      const url = 'verify-otp-login'
      try {
        const result = await this.$axios.$post(url, {
          phone: this.phone,
          code: this.otp,
          email: this.email,
          otpOption: this.otpOption,
          accept_term: 1
          // accept_term: this.consents.accepted ? this.consents.accepted : 0
        })
        this.hideLoading()
        if (result.status === true) {
          this.$store.dispatch('authenticateSmsUser', result)
          const updateLineLiff = await this.$axios.$post('update-liff/' + this.userId, { user_id: this.lineProfile.sub, token: this.lineToken })
          console.log('updateLineLiff >> ', updateLineLiff)
          if (this.lineProfile.sub !== undefined) {
            Swal.fire({
              title: 'คุณได้ทำการเชื่อมต่อบัญชี กับไลน์เรียบร้อยแล้ว',
              icon: 'success'
            }).then(() => {
              window.liff.closeWindow()
            })
          }
          // this.$router.push('/')
        } else if (result.status === false) {
          this.setAlert({
            show: true,
            message: result.message,
            header: 'แจ้งเตือน'
          })
        }
      } catch (e) {
        console.log('error', e.message)
        Swal.fire({
          title: 'รหัสยืนยันไม่ถูกต้อง ',
          icon: 'error'
        })
      }
      this.hideLoading()
    },
    handleReauthentication () {
      console.log('Re-authenticating user...')
      // Redirect to login if not already logged in
      window.liff.login({
        scopes: ['profile', 'email']
      })
    },
    isTokenExpired (token) {
      // Example function to check if the token is expired
      const decodedToken = this.parseJwt(token)
      const currentTime = Math.floor(Date.now() / 1000) // Current time in seconds
      return decodedToken.exp < currentTime
    },
    parseJwt (token) {
      // Function to decode JWT token and get the payload
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))

      return JSON.parse(jsonPayload)
    },
    async checkSessionValidity () {
      // const decodedIDToken = await window.liff.getDecodedIDToken()
      const decodedIDToken = await JSON.parse(localStorage.getItem('decodedIDToken'))
      console.log('linePROFILE >>>', decodedIDToken)
      const now = Math.floor(Date.now() / 1000) // เวลาปัจจุบันในหน่วยวินาที
      // console.log('>>>> NOW <<<<', now)
      if (decodedIDToken.exp > now) {
        try {
          const profile = await window.liff.getProfile()
          console.log('User profile:', profile)
          // ใช้ข้อมูลโปรไฟล์ในแอปของคุณ
        } catch (err) {
          console.error('Error getting profile:', err)
        }
      } else {
        console.log('TOKEN EXPIREDDDD')
        // Token หมดอายุ ล็อกเอาท์และล็อกอินใหม่
        // window.liff.logout()
        // window.liff.login()
      }
      // ดึงข้อมูลโปรไฟล์ของผู้ใช้จาก Line
      // const profile = await window.liff.getProfile()
      // console.log('User profile:', profile)
      // if (window.liff.isLoggedIn()) {
      //   window.liff.getDecodedIDToken().then((decodedIDToken) => {
      //     const now = Math.floor(Date.now() / 1000) // เวลาปัจจุบันในหน่วยวินาที
      //     if (decodedIDToken.exp > now) {
      //       // Token ยังไม่หมดอายุ
      //       window.liff.getProfile().then((profile) => {
      //         console.log('User profile:', profile)
      //       }).catch(err => console.error('Error getting profile:', err))
      //     } else {
      //       // Token หมดอายุ
      //       window.liff.logout() // ล็อกเอาท์เพื่อล็อกอินใหม่
      //       window.liff.login()
      //     }
      //   }).catch((err) => {
      //     console.error('Error decoding ID token:', err)
      //   })
      // }
    },
    handleKeyPress (evt) {
      if (evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
        evt.preventDefault()
      }

      // const value = evt.target.value
      // if (value.length > 6) {
      //   evt.target.value = value.slice(0, 6)
      //   this.otp = evt.target.value; // Update v-model value
      // }
    },
    handleInputOTP (event) {
      const value = event.target.value
      if (value.length > 6) {
        event.target.value = value.slice(0, 6) // Limit to 6 characters
        this.otp = event.target.value // Update v-model value
      }
    }
  }
}
